export const environment = {
    tokenName: 'access_token',
    refreshTokenName: 'refresh_token',
    type: 'staging',
  apiEndpoint: 'https://laapitest.deepseas.com',
    oktaOidc: {
      clientId: '0oa1se6e36hnlj8pp1d8',
      issuer: 'https://deepseas-cs.okta.com/oauth2/default',
      redirectUri: 'https://logtest.deepseas.com/login/callback',
      scopes: ['openid', 'profile', 'email'],
      testing: {
        disableHttpsCheck: 'true'
      }
    },
    oktaOidc2: {
        clientId: '0oa1nj8a7thTgULdL0h8',
        issuer: 'https://sso-dev.deepseas.com/oauth2/default',
        redirectUri: 'https://portal5test.securityondemand.com/login/callback',
        scopes: ['openid', 'profile', 'email'],
        testing: {
            disableHttpsCheck: 'true'
        }
    }
};
