export interface DateOption {
  id: number;
  name: string;
}

export class DateRange {
  startDateUtc: string;
  endDateUtc: string;
  timePeriod: string;
  label: string;
  isCustom?: boolean = false;
  customLabel?: string = '';
  fullLabel?: any;
  focusedDateRange?: boolean = false;
}

export class CustomDate {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export enum DateRangeOption {
  LastHour = "Last Hour",
  LastTwoHours = "Last 2 Hours",
  LastFourHours = "Last 4 Hours",
  LastSixHours = "Last 6 Hours",
  LastEightHours = "Last 8 Hours",
  LastTwelveHours = "Last 12 Hours",
  LastTwentyFourHours = "Last 24 Hours",
  LastSevenDays = "Last 7 Days",
  LastThirtyDays = "Last 30 Days",
  Custom = "Custom",
  LastSixtyDays = "Last 60 Days",
  LastNinetyDays = "Last 90 Days",
  LastOneHundredTwentyDays = "Last 120 Days",
  LastMonth = "Previous Month",
}

export enum SummaryBehaviorDateRangeOption {
  TwentyFourHours = "24 Hours",
  SevenDays = "7 Days",
  ThirtyDays = "30 Days",
}
