import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserService } from '../+home/_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {

  constructor(private router: Router, private http: HttpClient, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.userService.getUserRoles()
      .pipe(
        map(response => {
          if (!response) {
            return false;
          }
          
          let result = false;
          let url = state.url.toLowerCase();
          
          if (url.includes('userbehavior')) {
            result = response.hasUserBehavior.data;
          } else if (url.includes('assetbehavior')) {
            result = response.hasAssetBehavior.data;
          } else if (url.includes('raw')) {
            result = response.hasStringSearch.data;
          } else if (url.includes('twhunt')) {
            result = response.hasThreatWatchHuntAccess.data;
          } else if (url.includes('nac')) {
            result = response.hasManagedNACAccess.data;
          } else if (url.includes('adps')) {
            result = response.hasADPSAccess.data;
          } else if (url.includes('dns')) {
            result = response.hasDNSAccess.data;
          } else if (url.includes('waf')) {
            result = response.hasManagedWAFAccess.data;
          } else if (url.includes('dhcp')) {
            result = response.hasDHCPAccess.data;
          } else if (url.includes('monitoring')) {
            result = response.hasResellerMonitoringAccess.data;
          } else if (url.includes('idps')) {
            result = response.hasIDPSAccess.data;
          } else if (url.includes('mfw')) {
            result = response.hasMFWAccess.data;
          } else if (url.includes('customalerts')) {
            result = response.hasCustomAlertsAccess.data;
          } else if (url.includes('audit')) {
            result = response.isSuperUser.data;
          } else if (url.includes('dyt')) {
            result = true; //TODO: is it flag driven?
          } else if (url.includes('beaconing')) {
            result = true; //TODO: is it flag driven?
          } else if (url.includes('behavioral-summary')) {
            result = response.hasSummaryBehavior.data; 
          }
          
          if (!result) {
            this.router.navigateByUrl('/');
          }

          return result;
        },
        error => {
          this.router.navigateByUrl('/');
          return false;
        })
      );
  }
}
