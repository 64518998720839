import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { combineLatest, from } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DateTimeService } from "./+home/_services/datetime.service";
import { UserService } from "./+home/_services/user.service";
import { DomSanitizer } from "@angular/platform-browser";
import { BrandService } from "./+home/_services/brand.service";
import {AuthService} from './_services';

@Injectable({
    providedIn: 'root'
})
export class AppResolverService implements Resolve<any> {
    constructor(
        private http: HttpClient,
        private dateTimeService: DateTimeService,
        private userService: UserService,
        private brandService: BrandService,
        private sanitizer: DomSanitizer,
        private authService: AuthService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

            return combineLatest(
                this.http.post('/client/GetClientLogo', null, { responseType: 'blob' }),
                this.http.post('/client/GetClientSettings', null),
                this.http.post('/user/GetUserSettings', null),
                this.http.post('/user/GetUserRoles', null),
                (clientLogo, clientSettings, userSettings, userRoles) => ({
                    clientLogo,
                    clientSettings,
                    userSettings,
                    userRoles
                })
            ).pipe(
                map(data => {
                    if (data.clientLogo && data.clientLogo.size) {
                        this.brandService.setLogoImage(data.clientLogo);
                    }

                    if (data.userSettings['timeZoneUtcOffset'] && data.userSettings['timeZoneUtcOffset'].success) {
                        this.dateTimeService.setUtcOffset(data.userSettings['timeZoneUtcOffset'].data);
                    }

                    if (data.userSettings['timeZoneAbbreviation'] && data.userSettings['timeZoneAbbreviation'].success) {
                        this.dateTimeService.setTimeZoneAbbreviation(data.userSettings['timeZoneAbbreviation'].data);
                    }

                    if (data.userSettings['clientName']) {
                        this.userService.setClientName(data.userSettings['clientName']);
                    }

                    if (data.userRoles['hasUserBehavior'] && data.userRoles['hasUserBehavior'].success) {
                        this.userService.setUserBehavior(data.userRoles['hasUserBehavior'].data);
                    }

                    if (data.userRoles['hasSummaryBehavior'] && data.userRoles['hasSummaryBehavior'].success) {
                        this.userService.setUserBehavior(data.userRoles['hasSummaryBehavior'].data);
                    }

                    if (data.userRoles['hasAssetBehavior'] && data.userRoles['hasAssetBehavior'].success) {
                        this.userService.setAssetBehavior(data.userRoles['hasAssetBehavior'].data);
                    }

                    if (data.clientSettings['skinTitle']) {
                        this.brandService.setTitle(data.clientSettings['skinTitle']);
                    }

                    if (data.clientSettings['maxDays']) {
                        this.dateTimeService.setMaxLogAvailableDays(data.clientSettings['maxDays']);
                    }

                    if (data.clientSettings['maxAlertDays']) {
                        this.dateTimeService.setMaxAlertAvailableDays(data.clientSettings['maxAlertDays']);
                    }

                    if (data.clientSettings['productId']) {
                        this.userService.setProductId(data.clientSettings['productId']);
                    }

                    if (data.clientSettings['serviceCenterUrl']) {
                      this.userService.setServiceCenterUrl(data.clientSettings['serviceCenterUrl']);
                    }

                    if (data.clientSettings['preferredDeviceInactivityThreshold']) {
                      this.userService.setPreferredDeviceInactivityThreshold(data.clientSettings['preferredDeviceInactivityThreshold']);
                    }

                  if (data.clientSettings['hasIntegrationProviders']) {
                    this.userService.setHasIntegrationProviders(data.clientSettings['hasIntegrationProviders']);
                  }

                    return data;
                })
            )
    }
}
