import { Log, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, EntityProperty, GridState } from "../../_models";

const getInitialValueForStates = (initialValue: any) => {
    const urls = [
        '/logs',
        '/alaap',
        '/logs/raw',
        '/logs/summary',
        '/logs/other/dhcp',
        '/',
        '/behavioral-summary'
    ];

    const result = {};
    urls.forEach((u) => result[u] = initialValue);
    return result;
};

export interface LogsState {
    dateRange: {[key: string]: DateRange};
    dateRangeOption: {[key: string]: DateRangeOption};
    drilldownDateRange: {[key: string]: DateRange};
    filterConditions: {[key: string]: ConfiguredFilter[]};
    summaryDateRange: {[key: string]: DateRange};
    summaryDateRangeOption: {[key: string]: DateRangeOption};
    summaryDrilldownDateRange: {[key: string]: DateRange};
    summaryFilterConditions: {[key: string]: ConfiguredFilter[]};

    behaviorType: string;
    users: string[];
    assets: string[];

    count: {[key: string]: number};
    isLoadingCount: {[key: string]: boolean};
    isCountLoaded: {[key: string]: boolean};
    summaryCount: {[key: string]: number};
    isLoadingSummaryCount: {[key: string]: boolean};
    isSummaryCountLoaded: {[key: string]: boolean};
    fullCount: {[key: string]: number};
    isLoadingFullCount: {[key: string]: boolean};
    isFullCountLoaded: {[key: string]: boolean};

    summaryEntities: {[key: string]: EntityProperty[]};
    summaryData: {[key: string]: any[]};
    isLoadingSummary: {[key: string]: boolean};
    isSummaryLoaded: {[key: string]: boolean};
    summaryGridState: {[key: string]: GridState};

    chartGroupingOption: {[key: string]: ChartGroupingOption};
    chartData: {[key: string]: any[]};
    isLoadingChart: {[key: string]: boolean};
    isChartLoaded: {[key: string]: boolean};
    fullChartData: {[key: string]: any[]};
    isLoadingFullChart: {[key: string]: boolean};
    isFullChartLoaded: {[key: string]: boolean};

    logs: {[key: string]: Log[]};
    isLoadingLogs: {[key: string]: boolean};
    isLogsLoaded: {[key: string]: boolean};
    summaryLogs: {[key: string]: Log[]};
    isLoadingSummaryLogs: {[key: string]: boolean};
    isSummaryLogsLoaded: {[key: string]: boolean};
    fullLogs: {[key: string]: Log[]};
    isLoadingFullLogs: {[key: string]: boolean};
    isFullLogsLoaded: {[key: string]: boolean};
    SummaryBehavior: {[key: string]: Log[]};
    isLoadingSummaryBehavior: {[key: string]: boolean};
    isSummaryBehaviorLoaded: {[key: string]: boolean};
    gridState: {[key: string]: GridState};
    isMasterDetailExpanded: {[key: string]: boolean};

    error: {[key: string]: Error};
};

export const initialLogsState: LogsState = {
    dateRange: getInitialValueForStates(null), // TODO
    dateRangeOption: getInitialValueForStates(DateRangeOption.LastTwentyFourHours),
    drilldownDateRange: getInitialValueForStates(null),
    filterConditions: getInitialValueForStates([]),
    summaryDateRange: getInitialValueForStates(null), // TODO
    summaryDateRangeOption: getInitialValueForStates(DateRangeOption.LastTwentyFourHours),
    summaryDrilldownDateRange: getInitialValueForStates(null),
    summaryFilterConditions: getInitialValueForStates([]),

    behaviorType: null,
    users: [],
    assets: [],

    count: getInitialValueForStates(0),
    isLoadingCount: getInitialValueForStates(false),
    isCountLoaded: getInitialValueForStates(false),
    summaryCount: getInitialValueForStates(0),
    isLoadingSummaryCount: getInitialValueForStates(false),
    isSummaryCountLoaded: getInitialValueForStates(false),
    fullCount: getInitialValueForStates(0),
    isLoadingFullCount: getInitialValueForStates(false),
    isFullCountLoaded: getInitialValueForStates(false),

    summaryEntities: getInitialValueForStates([]),
    summaryData: getInitialValueForStates(undefined),
    isLoadingSummary: getInitialValueForStates(false),
    isSummaryLoaded: getInitialValueForStates(false),
    summaryGridState: getInitialValueForStates(null),

    chartGroupingOption: getInitialValueForStates(null),
    chartData: getInitialValueForStates(undefined),
    isLoadingChart: getInitialValueForStates(false),
    isChartLoaded: getInitialValueForStates(false),
    fullChartData: getInitialValueForStates(undefined),
    isLoadingFullChart: getInitialValueForStates(false),
    isFullChartLoaded: getInitialValueForStates(false),

    logs: getInitialValueForStates(undefined),
    isLoadingLogs: getInitialValueForStates(false),
    isLogsLoaded: getInitialValueForStates(false),
    summaryLogs: getInitialValueForStates(undefined),
    isLoadingSummaryLogs: getInitialValueForStates(false),
    isSummaryLogsLoaded: getInitialValueForStates(false),
    fullLogs: getInitialValueForStates(undefined),
    isLoadingFullLogs: getInitialValueForStates(false),
    isFullLogsLoaded: getInitialValueForStates(false),
    SummaryBehavior: getInitialValueForStates(undefined),
    isLoadingSummaryBehavior: getInitialValueForStates(false),
    isSummaryBehaviorLoaded: getInitialValueForStates(false),
    gridState: getInitialValueForStates(null),
    isMasterDetailExpanded: getInitialValueForStates(true),

    error: getInitialValueForStates(null)
};
